import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Full Migration For Our Environments",
  "author": "tzookb",
  "type": "post",
  "date": "2020-02-11T13:50:08.000Z",
  "url": "/full-environments-migrations",
  "featuredImage": "./thumb.jpg",
  "categories": ["javascript", "automation"]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`I had a great project recently that required interacting with several different pieces on the stack.`}</p>
    <p>{`The project was to create a script for helping the team migrate full environemnts from one another.`}</p>
    <p>{`That means if they are changing production content, they could easily migrate everything to the staging environemnt
or any other developer environment.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#environment-stack-explained"
        }}>{`Environment Stack Explained`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#requirements"
        }}>{`Requirements`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#problems-and-performence"
        }}>{`Problems and Performence`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "#script-flow"
        }}>{`Script Flow`}</a></li>
    </ul>
    <h2>{`Environment Stack Explained`}</h2>
    <a id="environment-stack-explained" />
    <p>{`The site itself is built with "Gatsby.js".
That means we use javascript, react, styled-components, hooks and more.`}</p>
    <p>{`For Gatsby we use data sources from "Contentful" and "Shopify".`}</p>
    <p>{`"Contentful" is used as a cms, as we set the pages structure, content,
landing pages, blog posts, product bundles and more.`}</p>
    <p>{`Shopify is used as the "source of truth" for the product data.
Pricing, stock, discounts, product details and images.`}</p>
    <p>{`The checkout process using Shopify as the prodvider as well.`}</p>
    <h2>{`Requirements`}</h2>
    <a id="requirements" />
    <p>{`These are the rough requirements:`}</p>
    <ul>
      <li parentName="ul">{`easy command to run and pass variables`}</li>
      <li parentName="ul">{`copy the data from one Contentful env to another`}</li>
      <li parentName="ul">{`copy the connected Shopify store to the destination Shopify store`}</li>
      <li parentName="ul">{`make sure all plugins are set properly with right credentials`}</li>
    </ul>
    <h2>{`Problems and Performence`}</h2>
    <a id="problems-and-performence" />
    <p>{`As I gave a quick brief on the tech stack and the requirements, there are some limits that pop up.
API limits from Shopify and Contenful. Time constraints, we want the migration to happen as fast as possible.
We want feedback that it happened successfully or failed.`}</p>
    <h2>{`The Script`}</h2>
    <a id="script-flow" />
    <p>{`After digging into some packages I found some great tools that will reduce the work.`}</p>
    <p>{`Contenful cli tool, has a nice command that migrates full environments.
That means we saved a ton of work on that side.
The only missing piece is updating the credentials for the extra plugins we have there.`}</p>
    <p>{`Next package is for easy integration with Shopify, that was quite easy.
Just creating a store with the needed tokens and we are good to good with easy api calls.`}</p>
    <p>{`Now the full flow of the script:`}</p>
    <ul>
      <li parentName="ul">{`validating parameters`}</li>
      <li parentName="ul">{`asking the client if he is sure on running the command with the above parameters`}</li>
      <li parentName="ul">{`run contenful migration script`}</li>
      <li parentName="ul">{`now doing Shopify updates:`}
        <ul parentName="li">
          <li parentName="ul">{`delete all destination store products if exists`}</li>
          <li parentName="ul">{`fetch origin store products`}</li>
          <li parentName="ul">{`transform the products for insertion on the destination store`}</li>
          <li parentName="ul">{`insert the adapted products to the destination store`}</li>
          <li parentName="ul">{`on caviat here, is that Shopify as api limits we can easily reach with many products`}
            <ul parentName="li">
              <li parentName="ul">{`the easy solution here is making one call every 0.5 sec`}</li>
              <li parentName="ul">{`that's because Shopify api refills api limit count with 2 calls per second`}</li>
            </ul>
          </li>
          <li parentName="ul">{`store the created products locally`}</li>
        </ul>
      </li>
      <li parentName="ul">{`now update Contenful products models with the newly created product IDs`}</li>
      <li parentName="ul">{`update Shopify store and add all products to the proper sales channel`}</li>
      <li parentName="ul">{`update Contenful plugin credentials`}</li>
      <li parentName="ul">{`done`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      